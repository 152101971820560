$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #dee2e6 !default;
$gray-300: #bdbdbd !default;
$gray-400: #999999 !default;
$gray-500: #afb4b9 !default;
$gray-600: #818991 !default;
$gray-700: #495057 !default;
$gray-800: #495057 !default;
$gray-900: #495057 !default;
$black:    #000 !default;

$blue:    #548fe7 !default;
$indigo:  #6610f2 !default;
$purple:  #8d60e2 !default;
$pink:    #d63384 !default;
$red:     #b9313f !default;
$orange:  #fd7e14 !default;
$yellow:  #fac935 !default;
$green:   #198754 !default;
$teal:    #3accc5 !default;
$cyan:    #50d4ee !default;

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $teal !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;

$body-color: $gray-500 !default;

$component-active-color: $gray-900 !default;
$component-active-bg: $gray-100 !default;

@import url('https://fonts.googleapis.com/css2?family=Raleway&Dancing+Script:wght@400;700&family=Fredericka+the+Great&family=Raleway&family=Roboto&display=swap');

$enable-rounded: true;
$enable-caret: false;
$enable-shadows: true;
$enable-gradients: true;
$font-family: Oswald;
$font-family-sans-serif: Raleway, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, !default;
$font-family-base: $font-family-sans-serif !default;

@import '~bootstrap/scss/bootstrap.scss';


.prof {
    z-index: 10000;
    // box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    position: relative;
    margin-top: -40px;
}

body {
    font-family: "Comfortaa", sans-serif;
}
  
.main-content {
    margin: 2em auto 0;
    text-align: center;
}
  
h1 {
    margin: 2em 0;
    color: #fff;
}

svg {
    display: inline-block;
}

path, polygon, circle, rect {
    transition: fill 1s ease;
}

// #controls {
//     background-color: rgba(255, 255, 255, 0.5);
// }
  
.illustration {
    position: relative;
    align-self: end;
    margin: auto;
    width: 250px;
    overflow: hidden;
}

.mountain, .hill, .land, .trees, .sun, .clouds, .moon, .stars {
    position: absolute;
    left: 0;
    margin: auto;
    right: 0;
}

.mountain {
    bottom: 45px;
}

.hill {
    bottom: 45px;
}

.trees {
    bottom: 25px;
}

.sun {
    top: 120px;
    margin-left: -20px;
    transition: all 1s ease;
    transform: scale(0.2);
}

.moon, .stars {
    top: 25px;
    opacity: 0;
}

.land {
    bottom: 3px;
}

.clouds {
    top: 50px;
    right: -500px;
    transition: all 1s ease .1s;
}

.day {
    .sun {
        top: 10px;
        margin-left: 0;
        transform: scale(1);
    }
    .clouds {
        right: 0;
    }
}

.night {
    .stars, .moon {
        opacity: 1;
        transition: all 1s ease .5s;
    }
    #sky {
        fill: #17377f;
    }
    #mountain {
        fill: #73addf;
    }
    #hill {
        fill: #659fcd;
    }
    #land {
        fill: #508bb5;
    }
    .trees {
        fill: #1c2c3b;
    }
    .trunks {
        fill: #3f3e3d;
    }
}

.sunset {
    .sun {
        top: 60px;
        margin-left: -80px;
        transform: scale(1);
    }
    #sun {
        fill: #fff;
    }
    #sky {
        fill: url(#sky_sunset);
    }
    #mountain {
        fill: #efbb2b;
    }
    #hill {
        fill: #e6ad28;
    }
    #land {
        fill: #de9f26;
    }
    .trees {
        fill: #747c0b;
    }
    .trunks {
        fill: #3f3e3d;
    }
}

.dusk {
    .sun {
        top: 60px;
        margin-left: 140px;
        transform: scale(1);
    }
    #sun {
        fill: #ffffda;
    }
    #sky {
        fill: url(#sky_dusk);
    }
    #mountain {
        fill: #f1a3a2;
    }
    #hill {
        fill: #e09c9c;
    }
    #land {
        fill: #c4918d;
    }
    .trees {
        fill: #ce791c;
    }
    .trunks {
        fill: #8c5f50;
    }
}


.chris-message {
    transition-property: transform, opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out, ease-out, ease-out, ease-in;
    transition-delay: 0.5s;

    transform: inherit;
    opacity: 1;    

    padding: 0.75rem 1.5rem;
    @extend .m-1;  
    @extend .bg-light;
    @extend .rounded-pill;
}
#links{
    transition-property: transform, opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out, ease-out, ease-out, ease-in;
    transition-delay: 0.5s;

    transform: inherit;
    opacity: 1;
}
.off{
    transform: translate(12px, 50%);
    opacity: 0 !important;
}

.link-message {
    @extend .btn;
    @extend .btn-outline-secondary;
    @extend .m-1;  
    @extend .bg-white;
    @extend .border;
    @extend .text-info-emphasis;
    @extend .rounded-pill;
}

.link-message.active {
    @extend .btn;
    @extend .border-info-subtle;    
    @extend .bg-info-subtle;
}

#homeContainer{
    background: url(../public/img/bg1.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
}
#homeContainer > .page-icons > a {
    background-color: #f8f9fa8e;
    @extend .btn;
    @extend .btn-light;    
    @extend .m-2;
}
#contentContainer{
    padding-left: 0;
    padding-right: 0;
}